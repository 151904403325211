const sections = {
  home: {
    HOME: "home",
    KEEP_SOLUTION: "keep-solution",
    MINILOGO_GRID: "minilogo-grid",
    GOVERNANCE_FORUM: "governance-forum",
    KEEP_BLOG: "keep-blog",
    EXCHANGES: "exchanges",
    LOGO_WALL: "logo-wall",
  },
  mint: {
    HOME: "home-mint",
    WHY: "why",
  },
  faq: {
    HOME: "home-faq",
    QUESTIONS: "questions",
  },
  press: {
    HOME: "home-press",
    MINILOGO_GRID: "minilogo-grid",
    NEWS: "keep-blog",
    LATEST_POST: "latest-post",
  },
  team: {
    HOME: "home-team",
    CEO_INFO: "ceo-info",
    BANNER: "team-banner",
    TEAM_GALLERY: "team-gallery",
    ADVISOR_GALLERY: "advisor-gallery",
  },
  info: {
    HOME: "home-info",
    FEATURE: "feature",
    FEATURE_CARD: "info-feature-card",
    TBTC_SHOWCASE: "tbtc-showcase",
    SECURE: "info-secure",
  },
}

export { sections }
